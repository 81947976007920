// src/pages/ithojo.js
import React from "react"



import LayoutLp from "../components/layoutLp"
import Seo from "../components/seo"
import LpContents from "../components/lpcontents"

export default function Ithojo() {

  return (
    <LayoutLp>
      <Seo title="IT導入補助金でEC制作" description="IT導入補助金で最大350万円おトクにECサイトを入手できる！" />
      <LpContents />
    </LayoutLp>

  )
}
